@import '../../../../scss/theme-bootstrap';

.gnav-section-formatter {
  max-height: min-content;
  transition: max-height 0.25s ease;
  overflow: hidden;
  @include breakpoint($landscape-only) {
    max-height: unset;
    transition: none;
    overflow: unset;
    padding: 0;
  }
  &[aria-expanded='true'] {
    max-height: 1500px;
    transition: max-height 0.75s ease-in-out;
    @include breakpoint($landscape-only) {
      max-height: unset;
      transition: none;
    }
  }
  &__trigger {
    display: none;
    @include breakpoint($landscape-only) {
      display: inline-block;
    }
  }
  &__mob_trigger {
    display: inline-block;
    @include breakpoint($landscape-only) {
      display: none;
    }
  }
  &__content-wrapper {
    flex-direction: column;
    height: auto;
    opacity: 1;
    position: relative;
    width: 100%;
    @include breakpoint($landscape-only) {
      transition: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      overflow-y: hidden;
      position: fixed;
      height: 100%;
      width: 0;
      top: 0;
      opacity: 0;
      z-index: 1;
      visibility: hidden;
      #{$ldirection}: 0;
    }
    &:after {
      @include breakpoint($landscape-only) {
        @include transition(width $gnav-second-panel-animation-duration $gnav-panel-animation-timing-function);
        content: '';
        background: $color-core-black;
        border-#{$ldirection}: 1px solid $color-white-op50;
        height: 100%;
        position: absolute;
        z-index: -1;
        #{$ldirection}: 0;
        width: 0;
      }
    }
    .site-header-formatter__subsection-trigger-reset:not(:checked) ~ .site-header-formatter__sections &:after {
      @include breakpoint($landscape-only) {
        width: 100%;
      }
    }
  }
  &__content-inner-wrapper {
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: auto;
    width: 100%;
    @include breakpoint($landscape-only) {
      display: flex;
      align-items: flex-start;
      overflow: hidden;
      height: 100%;
      flex-direction: row;
      top: 0;
      width: 100%;
    }
    .gnav-section-formatter__mob_trigger:checked ~ .gnav-section-formatter__content-wrapper & {
      display: flex;
    }
  }
  &__link,
  &__subsection-label {
    @include gnav-category-link();
    cursor: pointer;
    display: none;
    @include breakpoint($landscape-only) {
      display: inline-block;
    }
    .site-header-formatter__subsection-trigger-reset:not(:checked) ~ .site-header-formatter__sections &,
    .gnav-section-formatter__trigger:checked ~ & {
      @include breakpoint($landscape-only) {
        display: inline-flex;
      }
    }
    .site-header-formatter__subsection-trigger-reset:checked ~ .site-header-formatter__sections & {
      &, a.gnav-section-formatter__header-link {
        @include breakpoint($landscape-only) {
          color: $color-white-op50;
        }
      }
    }
    .site-header-formatter__subsection-trigger-reset:not(:checked) ~ .site-header-formatter__sections & {
      &,
      a.gnav-section-formatter__header-link {
        @include breakpoint($landscape-only) {
          color: $color-white-op50;
        }
      }
    }
    .site-header-formatter__subsection-trigger-reset:not(:checked) ~ .site-header-formatter__sections
    .gnav-section-formatter__trigger:checked ~ & {
      &,
      a.gnav-section-formatter__header-link {
        @include breakpoint($landscape-only) {
          color: $color-white;
        }
      }
    }
  }
  &__header-label {
    @include gnav-category-link();
    position: relative;
    display: block;
    width: 100%;
    padding-#{$ldirection}: $container-pad-small;
    @include breakpoint($landscape-only) {
      display: none;
    }
  }
  &__header-link {
    color:  $color-white-op50;
    text-decoration: none;
    .gnav-section-formatter__mob_trigger:checked ~ .gnav-section-formatter__header-label & {
      color:  $color-white;
    }
  }
  &__mobile-section-toggle {
    opacity: 1;
    position: absolute;
    top: -2px;
    #{$rdirection}: 20px;
    @include breakpoint($landscape-only) {
      display: none;
    }
  }
  &__mobile-icon {
    fill: $color-white;
    width: 14px;
    height: 14px;
    &.icon--add {
      display: inline-block;
      .gnav-section-formatter__mob_trigger:checked ~ .gnav-section-formatter__header-label & {
        display: none;
      }
    }
    &.icon--minus {
      display: none;
      .gnav-section-formatter__mob_trigger:checked ~ .gnav-section-formatter__header-label & {
        display: inline-block;
      }
    }
  }
  // Only displays for mobile, takes user back to main sections list.
  &__mobile-back {
    @include gnav-mobile-back();
    display: none;
    &-label {
      font-size: 12px;
    }
    &-icon {
      display: inline-block;
      fill: $color-black;
      opacity: 1;
      position: relative;
      width: $gnav-icon--inline;
      height: $gnav-mobile-link-height;
      @include breakpoint($landscape-only) {
        background: none;
        display: none;
      }
      &:hover,
      &:active {
        fill: $color-black;
      }
    }
  }
  &__gnav-links-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 0;
    @include breakpoint($landscape-only) {
      @include swap_direction(padding, 0 0 0 $container-pad-landscape);
      @include transition(visibility $gnav-second-panel-animation-duration $gnav-content-animation-timing-function, opacity $gnav-second-panel-animation-duration $gnav-content-animation-timing-function);
      justify-content: flex-start;
      flex-direction: column;
      position: relative;
      order: 1;
      width: 92%;
      margin-top: $header-nav-top-pos + 15px;
      overflow-y: auto;
      height: calc(100% - #{$header-nav-top-pos} - 15px);
      opacity: 0;
      visibility: hidden;
      scrollbar-color: $color-white $color-core-black;
      scrollbar-width: thin;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-white;
      border-radius: 20px;
    }
    .gnav-section-formatter__trigger:checked~.gnav-section-formatter__content-wrapper
    .gnav-section-formatter__content-inner-wrapper & {
      @include breakpoint($landscape-only) {
        opacity: 1;
        visibility: visible;
      }
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-first & {
      @include breakpoint($landscape-only) {
        order: 2;
      }
    }
    .gnav-section-formatter__link + & {
      height: 0;
      @include breakpoint($landscape-only) {
        height: auto;
      }
    }
    .gnav-tout {
      display: none;
      @include breakpoint($landscape-only) {
        background: $color-core-black;
        display: block;
      }
    }
    .gnav-links {
      padding: 15px 0;
      @include breakpoint($landscape-only) {
        height: auto;
      }
      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  &__tout {
    display: none;
    @include breakpoint($landscape-only) {
      order: 2;
      height: 100%;
      width: $gnav-third-panel-width;
    }
    .gnav-section-formatter--tout-enabled & {
      @include breakpoint($landscape-only) {
        display: flex;
      }
    }
    .gnav-tout {
      @include breakpoint($landscape-only) {
        @include transition(all 1s ease);
        opacity: 0;
      }
      .gnav-section-formatter__trigger:checked ~ .gnav-section-formatter__content-wrapper 
      .gnav-section-formatter__content-inner-wrapper & {
        @include breakpoint($landscape-only) {
          opacity: 1;
        }
      }
      &__wrapper {
        @include breakpoint($landscape-only) {
          height: 100%;
        }
      }
      &__media-wrapper {
        @include breakpoint($landscape-only) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          height: auto;
          padding-top: 40px;
        }
      }
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-first & {
      @include breakpoint($landscape-only) {
        order: 1;
        padding-top: 0;
      }
    }
    .site-header-formatter__subsection-trigger-reset:checked~.site-header-formatter__sections 
    .gnav-section-formatter__content-wrapper &::after {
      @include breakpoint($landscape-only) {
        @include transition(opacity 1s ease);
        content: '';
        opacity: 0;
        z-index: -1;
        width: 0;
        height: 0;
      }
    }

    .site-header-formatter__subsection-trigger-reset:not(:checked)~.site-header-formatter__sections 
    .gnav-section-formatter__trigger:checked~.gnav-section-formatter__content-wrapper .gnav-section-formatter__content-inner-wrapper 
    &:after {
      @include breakpoint($landscape-only) {
        @include transition(opacity 1s ease);
        content: '';
        opacity: 1;
        visibility: visible;
        background-color: $color-core-black;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
      }
    }
  }
  &__see-all {
    padding: 10px 0;
    @include breakpoint($landscape-only) {
      padding: 0;
    }
    &-link {
      @include gnav-text-link();
    }
  }
  input[type='radio'] ~ label {
    &::before {
      display: none;
    }
  }
}
